:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, "Cascadia Mono", "Segoe UI Mono",
    "Roboto Mono", "Oxygen Mono", "Ubuntu Monospace", "Source Code Pro",
    "Fira Mono", "Droid Sans Mono", "Courier New", monospace;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  /* DO NOT ADD overflow-x: hidden AS IT MESSES WITH STICKY POSITIONING */
}

div#__next {
  min-height: 100vh;
}

@font-face {
font-family: '__graphik_2dc19c';
src: url(/_next/static/media/660df1574f498e36-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
}

@font-face {
font-family: '__graphik_2dc19c';
src: url(/_next/static/media/5c8fffff95eb8e48-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
}

@font-face {
font-family: '__graphik_2dc19c';
src: url(/_next/static/media/94696cc1efb27449-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
}

@font-face {
font-family: '__graphik_2dc19c';
src: url(/_next/static/media/e9398eb2939db276-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
}@font-face {font-family: '__graphik_Fallback_2dc19c';src: local("Arial");ascent-override: 76.92%;descent-override: 17.11%;line-gap-override: 9.40%;size-adjust: 106.34%
}.__className_2dc19c {font-family: '__graphik_2dc19c', '__graphik_Fallback_2dc19c'
}

